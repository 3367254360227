import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  getAuth,
  getAdditionalUserInfo,
  UserCredential,
  applyActionCode,
  checkActionCode,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { setDoc, updateDoc, doc } from 'firebase/firestore';
import { db, functions } from '@/firebase/firebase';
import * as gtag from '@/lib/gtag';
import eventAdd from '@/lib/eventAdd';
import { IAuthResponse } from '../types';
import {
  AuthError,
  AuthErrorResponse,
  AuthSuccessResponse,
} from './authResponse';

const userLoginEvents = (userCredential: UserCredential, method: string) => {
  const userInfo = getAdditionalUserInfo(userCredential);
  if (userInfo) {
    if (userInfo.isNewUser) {
      eventAdd({
        uid: userCredential.user.uid,
        eventType: 'USER:REGISTER',
        eventSource: 'USER',
        eventSourceId: userCredential.user.uid,
        meta: {
          method,
        },
        email: userCredential.user.email,
        platform: 'web',
      });
      gtag.event({
        action: 'user.signin.new',
        params: {
          uid: userCredential.user.uid,
          provider: userInfo.providerId,
        },
      });
    } else {
      eventAdd({
        uid: userCredential.user.uid,
        eventType: 'USER:SIGNIN',
        eventSource: 'USER',
        eventSourceId: userCredential.user.uid,
        meta: {
          method,
        },
        email: userCredential.user.email,
        platform: 'web',
      });
      gtag.event({
        action: 'user.signin.returning',
        params: {
          uid: userCredential.user.uid,
          provider: userInfo.providerId,
        },
      });
    }
  }
};

export const signInSocial = async (
  provider: 'Facebook' | 'Google',
): Promise<IAuthResponse> => {
  let authProvider = null;
  switch (provider) {
    case 'Facebook':
      authProvider = new FacebookAuthProvider();
      break;

    case 'Google':
    default:
      authProvider = new GoogleAuthProvider();
      break;
  }
  if (!authProvider) {
    return new AuthErrorResponse('No auth provider');
  }

  const auth = getAuth();

  return signInWithPopup(auth, authProvider)
    .then(async userCredential => {
      const { uid } = userCredential.user;
      try {
        // All sign in with social providers should be verified
        const updateSocialUser = httpsCallable(
          functions,
          'profileCallable-verifyFacebookUser',
        );
        await updateSocialUser({ uid });

        userLoginEvents(userCredential, 'social');

        return new AuthSuccessResponse();
      } catch (err) {
        return new AuthErrorResponse(err);
      }
    })
    .catch(error => {
      return new AuthErrorResponse(error);
    });
};

export const signInEmail = async (email: string): Promise<IAuthResponse> => {
  if (!email) {
    return new AuthErrorResponse('Please supply an email address');
  }
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/home`,
    // This must be true.
    handleCodeInApp: true,
  };

  const auth = getAuth();

  return sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      return new AuthSuccessResponse();
    })
    .catch(err => new AuthErrorResponse(err));
};

export const signInEmailAndPassword = async (
  email: string,
  password: string,
): Promise<IAuthResponse> => {
  if (!email || !password) {
    return new AuthErrorResponse('Please supply an email address and password');
  }

  const auth = getAuth();

  return signInWithEmailAndPassword(auth, email, password)
    .then(async userCredential => {
      if (userCredential.user.uid) {
        setDoc(
          doc(db, 'settings', userCredential.user.uid),
          { setPassword: true },
          { merge: true },
        );

        userLoginEvents(userCredential, 'email');

        return new AuthSuccessResponse();
      }
      return new AuthErrorResponse('Sorry, login unsuccessful');
    })
    .catch(err => new AuthErrorResponse(err));
};

export const verifyEmailCode = async (
  uid: string,
  code: string,
): Promise<IAuthResponse> => {
  const auth = getAuth();
  return applyActionCode(auth, code)
    .then(() => {
      updateDoc(doc(db, 'settings', uid), { emailVerified: true });
      gtag.event({
        action: 'user.verifyemail.success',
      });
      eventAdd({
        uid,
        eventType: 'VERIFY_EMAIL:SUCCESS',
        eventSource: 'VERIFY_EMAIL',
        eventSourceId: uid,
        meta: {},
        platform: 'web',
      });
      return new AuthSuccessResponse();
    })
    .catch(err => {
      eventAdd({
        uid,
        eventType: 'VERIFY_EMAIL:ERROR',
        eventSource: 'VERIFY_EMAIL',
        eventSourceId: uid,
        meta: {},
        platform: 'web',
      });
      gtag.event({
        action: 'user.verifyemail.error',
        params: {
          code: err.code,
          error: err.message,
        },
      });
      return new AuthErrorResponse(err);
    });
};

export const recoverEmail = async (code: string): Promise<IAuthResponse> => {
  const auth = getAuth();
  return checkActionCode(auth, code)
    .then(() => {
      return applyActionCode(auth, code); // TODO: THERE'S AN ERROR HERE
    })
    .then(() => {
      gtag.event({
        action: 'user.restoreemail.success',
      });
      return new AuthSuccessResponse();
    })
    .catch(err => {
      gtag.event({
        action: 'user.restoreemail.error',
        params: {
          code: err.code,
          error: err.message,
        },
      });
      return new AuthErrorResponse(err);
    });
};

export const completeSignInEmail = async (
  providedEmail: string | null = null,
): Promise<IAuthResponse | null> => {
  const auth = getAuth();
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email: string | null = null;
    if (providedEmail) {
      email = providedEmail;
    } else {
      email = window.localStorage.getItem('emailForSignIn');
    }
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      return new AuthErrorResponse(
        new AuthError(
          'shiftms/confirm-email',
          'Please confirm your email address.',
        ),
      );
    }
    // The client SDK will parse the code from the link for you.
    return signInWithEmailLink(auth, email, window.location.href)
      .then(userCredential => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        userLoginEvents(userCredential, 'magiclink');
        return new AuthSuccessResponse(); // TODO: Redirect to /stream or /settings/updateEmail (if window.location.href.includes('updateEmail'))
      })
      .catch(err => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        return new AuthErrorResponse(err);
      });
  }
  return null;
};
