// https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
import validator from 'email-validator';
import { useCallback, useState } from 'react';

interface EmailInputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isValid: boolean;
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  showError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: (email: string) => void;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  reset: () => void;
  bind: {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: () => void;
  };
}

const useEmailInput = (initialValue: string): EmailInputProps => {
  const [value, setValue] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    if (validator.validate(email)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleChange = useCallback((email: string) => {
    if (email !== '') {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
    setValue(email);
    validateEmail(email);
  }, []);

  const reset = useCallback(() => {
    setValue('');
    setIsValid(false);
    setIsDirty(false);
    setShowError(false);
  }, []);

  const bind = {
    value,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value),
    onBlur: () => {
      if (!isValid && isDirty) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    },
  };

  return {
    value,
    setValue,
    isValid,
    isDirty,
    setIsDirty,
    showError,
    setShowError,
    handleChange,
    setIsValid,
    reset,
    bind,
  };
};

export default useEmailInput;
