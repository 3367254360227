import { useState } from 'react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import Icon from '@mdi/react';
import { UseInputReturnType } from '@/hooks/inputHook';
import { UsePasswordInputReturnType } from '@/hooks/passwordInputHook';
// TODO: Password type toggle button

const PasswordInput = ({
  passwordInput,
  label = (
    <>
      Enter your password <span>*</span>
    </>
  ),
  bindField = 'bind',
  showHelp = false,
}: {
  passwordInput: UseInputReturnType | UsePasswordInputReturnType;
  label?: JSX.Element;
  bindField?: 'bind' | 'bind1' | 'bind2';
  showHelp?: boolean;
}): JSX.Element => {
  const [passwordType, setPasswordType] = useState<'password' | 'text'>(
    'password',
  );

  const handlePasswordTypeToggle = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else setPasswordType('password');
  };

  return (
    <div className="field">
      <label
        id="profile-email-password-label"
        className="label"
        htmlFor="profile-email-password">
        {label}
      </label>
      {showHelp && (
        <p className="help">
          Ensure your password has a minimum of nine characters and includes
          numbers, letters and symbols.
        </p>
      )}
      <div className="field has-addons">
        <div className="control is-expanded">
          <input
            id="profile-email-password"
            className="input is-medium mb-4"
            {...passwordInput[bindField]}
            type={passwordType}
            aria-labelledby="profile-email-password-label"
            tabIndex={0}
            autoComplete="new-password"
          />
        </div>
        <div className="control">
          <button
            type="button"
            className="showPassword button is-medium"
            onClick={handlePasswordTypeToggle}>
            <span className="icon">
              <Icon
                path={passwordType === 'password' ? mdiEye : mdiEyeOff}
                size={1}
              />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
