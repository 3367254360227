import {
  sendPasswordResetEmail,
  getAuth,
  verifyPasswordResetCode as firebaseVerifyPasswordResetCode,
  confirmPasswordReset as firebaseConfirmPasswordReset,
} from 'firebase/auth';
import * as gtag from '@/lib/gtag';
import { IAuthResponse } from '../types';
import { AuthErrorResponse, AuthSuccessResponse } from './authResponse';

export const resetPassword = async (email: string): Promise<IAuthResponse> => {
  const auth = getAuth();
  try {
    await sendPasswordResetEmail(auth, email);
    return new AuthSuccessResponse();
  } catch (err) {
    return new AuthErrorResponse(err);
  }
};

export const verifyPasswordResetCode = async (
  code: string,
): Promise<IAuthResponse | false> => {
  const auth = getAuth();
  return firebaseVerifyPasswordResetCode(auth, code)
    .then(email => new AuthSuccessResponse(email))
    .catch(err => {
      gtag.event({
        action: 'user.passwordresetverify.error',
        params: {
          code: err.code,
          error: err.message,
        },
      });
      return new AuthErrorResponse(err);
    });
};

export const confirmPasswordReset = async (
  code: string,
  newPassword: string,
): Promise<IAuthResponse> => {
  const auth = getAuth();

  return firebaseConfirmPasswordReset(auth, code, newPassword)
    .then(() => new AuthSuccessResponse())
    .catch(err => {
      gtag.event({
        action: 'user.confirmpasswordreset.error',
        params: {
          code: err.code,
          error: err.message,
        },
      });
      return new AuthErrorResponse(err);
    });
};
