import { mdiAlert } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';

const AuthFormHelp = ({ type = 'warning', message }) => (
  <div className="form-error">
    <span className={type === 'warning' ? 'has-text-warning' : ''}>
      <Icon path={mdiAlert} size={1.25} />
    </span>

    <p className="help">{message}</p>
  </div>
);

AuthFormHelp.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
};

AuthFormHelp.defaultProps = {
  type: 'warning',
};

export default AuthFormHelp;
