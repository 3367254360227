import React from 'react';
import { ShiftmsError } from '@/providers/AuthProvider/types';

const AuthErrorMessage = ({
  error,
  showPasswordError = false,
}: {
  error: ShiftmsError;
  showPasswordError?: boolean;
}): JSX.Element | null => {
  if (
    !error ||
    (!showPasswordError &&
      'code' in error &&
      (error.code === 'auth/user-not-found' ||
        error.code === 'auth/wrong-password'))
  ) {
    return null;
  }

  return (
    <div className="is-size-6 py-5">
      <div className={'notification auth-message is-warning is-light'}>
        {'code' in error &&
        (error.code === 'auth/user-not-found' ||
          error.code === 'auth/wrong-password')
          ? 'Sorry, you entered an incorrect password.'
          : error.message}
      </div>
    </div>
  );
};

export default AuthErrorMessage;
