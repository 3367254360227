import {
  mdiAlert,
  mdiCheckCircle,
  mdiFacebook,
  mdiGoogle,
  mdiOpenInNew,
} from '@mdi/js';
import Icon from '@mdi/react';
import { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import Router from 'next/router';
import { useEffect, useState } from 'react';
import { IAuthError, FirebaseError } from '@/providers/AuthProvider/types';
import { AuthError } from '@/providers/AuthProvider/lib/authResponse';
import AuthFormHelp from '@/components/Auth/AuthFormHelp';
import PageLoading from '@/components/General/PageLoading';
import useEmailInput from '@/hooks/emailInputHook';
import useInput from '@/hooks/inputHook';
import { useAuth } from '@/providers/AuthProvider';
import {
  signInSocial,
  signInEmail,
  signInEmailAndPassword,
} from '@/providers/AuthProvider/lib/signinUser';
import { resetPassword } from '@/providers/AuthProvider/lib/resetPassword';
import AuthErrorMessage from '@/components/Auth/AuthErrorMessage';
import PasswordInput from '@/components/Auth/PasswordInput';

const SignIn: NextPage = () => {
  const { authLoaded, isLoggedIn } = useAuth();

  const [isMagicLinkSent, setIsMagicLinkSet] = useState<boolean>(false);
  const [isEmailResetSent, setIsEmailResetSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [passwordShowError, setPasswordShowError] = useState<boolean>(false);
  const [error, setError] = useState<FirebaseError | IAuthError | null>(null);
  const [lastEmail, setLastEmail] = useState<string | null>(null);

  const {
    value: email,
    bind: bindEmail,
    reset: emailReset,
    isDirty: emailDirty,
    showError: emailShowError,
    setShowError: setEmailShowError,
    isValid: emailValid,
  } = useEmailInput('');

  const passwordInput = useInput('');

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const handleLogin = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setEmailShowError(true);
    if (passwordInput.value === '') {
      setPasswordShowError(true);
    }
    if (email !== '' && emailValid && passwordInput.value !== '') {
      setError(null);
      setIsLoading(true);
      const result = await signInEmailAndPassword(email, passwordInput.value);
      passwordInput.reset();
      if (result.success) {
        emailReset();
        Router.push('/stream');
      } else {
        setLastEmail(email);
        emailReset();
        setError(result.error);
        setIsLoading(false);
      }
    } else {
      setError(
        new AuthError(
          'shiftms/password-missing',
          'Please correct the form errors and try again.',
        ),
      );
    }
  };

  const handleMagicLink = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (email !== '' && emailValid) {
      setError(null);
      setIsLoading(true);
      const result = await signInEmail(email);
      passwordInput.reset();
      setIsLoading(false);
      if (result.success) {
        emailReset();
        setIsMagicLinkSet(true);
      } else {
        setLastEmail(email);
        emailReset();
        setError(result.error);
      }
    } else {
      setError(
        new AuthError(
          'shiftms/no-email-address',
          'Please enter a valid email address.',
        ),
      );
    }
  };

  const handlePasswordReset = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (lastEmail) {
      setError(null);
      setIsLoading(true);
      const result = await resetPassword(lastEmail);
      if (result.success) {
        setIsEmailResetSent(true);
      } else {
        setError(result.error);
      }
      setIsLoading(false);
    }
  };

  const clickSignInSocial = async (provider: 'Facebook' | 'Google') => {
    setIsLoading(true);
    setError(null);
    const result = await signInSocial(provider);
    if (result.success) {
      Router.push('/stream');
    } else {
      setError(result.error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsRedirecting(true);
    };
    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  });

  useEffect(() => {
    if (isLoggedIn && !isLoading && !isRedirecting) {
      Router.push('/home');
    }
  }, [isLoggedIn, isLoading, isRedirecting]);

  if (!authLoaded || isRedirecting) {
    return <PageLoading />;
  }

  if (isMagicLinkSent) {
    return (
      <div className="hero is-fullheight">
        <div className="hero-body">
          <div className="container is-narrow has-text-centered">
            <div className="box">
              <div className="has-text-success">
                <Icon path={mdiCheckCircle} size={3} />
              </div>
              <p className="is-font-brand is-size-3 mb-4">
                Please check your email for your personal sign in link.
              </p>
              <div>
                <Link href="/home" legacyBehavior>
                  <a className="button is-primary is-rounded">
                    <span className="icon">
                      <Icon path={mdiOpenInNew} size={1} />
                    </span>
                    <span>Go to the home page</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isEmailResetSent) {
    return (
      <div className="hero is-fullheight">
        <div className="hero-body">
          <div className="container is-narrow has-text-centered">
            <div className="box">
              <div className="has-text-success">
                <Icon path={mdiCheckCircle} size={3} />
              </div>
              <p className="is-font-brand is-size-3 mb-4">
                Please check your email for your password reset link.
              </p>
              <div>
                <Link href="/home" legacyBehavior>
                  <a className="button is-primary is-rounded">
                    <span className="icon">
                      <Icon path={mdiOpenInNew} size={1} />
                    </span>
                    <span>Go to the home page</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head>
        <title>Sign in to Shift.ms</title>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/signin`}
        />
        <meta property="og:type" content="website" />
      </Head>
      <div className="signin-page">
        <div className="container is-narrow">
          <div className="signin-box">
            <div className="content is-medium">
              <div className="signin-form is-clearfix">
                <h1 className="mb-3">Welcome back</h1>
                <p className="is-font-brand">
                  You can sign in with an email address and password, or just
                  enter your email to get a secure magic link.
                </p>
                <AuthErrorMessage error={error} />
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label" htmlFor="signinEmail">
                      Enter your email address <span>*</span>
                    </label>
                    <div className="control has-icons-right">
                      <input
                        className={`input is-medium${
                          emailDirty && !emailValid && emailShowError
                            ? ' is-warning'
                            : ''
                        }${emailDirty && emailValid ? ' is-success' : ''}`}
                        type="email"
                        placeholder="email@me.com"
                        {...bindEmail}
                        tabIndex={0}
                        id="signinEmail"
                        autoComplete="email"
                      />
                      {emailDirty && !emailValid && emailShowError ? (
                        <span className="icon is-small is-right has-text-warning">
                          <Icon path={mdiAlert} size={1} />
                        </span>
                      ) : null}
                      {emailValid ? (
                        <span className="icon is-small is-right has-text-success">
                          <Icon path={mdiCheckCircle} size={1} />
                        </span>
                      ) : null}
                    </div>
                    {!emailValid && emailShowError ? (
                      <AuthFormHelp message="Please check your email address is correct." />
                    ) : (
                      ''
                    )}
                  </div>
                  {emailValid && emailDirty ? (
                    <div className="magiclink-prompt">
                      <div className="magiclink-prompt-content">
                        <p className="magiclink-prompt-text is-font-brand">
                          You can log in using a magic link to avoid typing a
                          long password.
                        </p>

                        <button
                          type="button"
                          tabIndex={-1}
                          className="button is-info is-rounded"
                          onClick={handleMagicLink}>
                          Email me a magic link
                        </button>
                      </div>
                    </div>
                  ) : null}
                  <PasswordInput passwordInput={passwordInput} />
                  <div className="field">
                    {passwordShowError ? (
                      <AuthFormHelp message="Please enter your password." />
                    ) : (
                      ''
                    )}
                    {error &&
                    (error.code === 'auth/user-not-found' ||
                      error.code === 'auth/wrong-password') ? (
                      <div className="magiclink-prompt mt-4">
                        <div className="magiclink-prompt-content">
                          <p className="magiclink-prompt-text is-font-brand">
                            Your username and password combination doesn&apos;t
                            match our records. If you are unsure, why not reset
                            your password.
                          </p>

                          <button
                            type="button"
                            tabIndex={-1}
                            className="button is-info is-rounded"
                            onClick={handlePasswordReset}>
                            Reset password
                          </button>
                        </div>
                      </div>
                    ) : (
                      <p className="help">
                        <Link href="/reset" className="has-text-white">
                          Not yet set a password? Click here to reset
                        </Link>
                      </p>
                    )}
                  </div>
                  <div className="form-buttons">
                    <div className="field mb-4">
                      <div className="control">
                        {isLoading ? (
                          <button
                            type="button"
                            className="button is-primary is-medium is-rounded is-fullwidth is-loading">
                            Login
                          </button>
                        ) : (
                          <button
                            type="submit"
                            tabIndex={0}
                            className="button is-primary is-medium is-rounded is-fullwidth"
                            onClick={handleLogin}>
                            Login
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="signin-other">
                <h2 className="title has-text-centered">Or...</h2>
                <div className="form-buttons mb-6">
                  <p className="control">
                    <button
                      type="button"
                      tabIndex={0}
                      className="button is-medium is-fullwidth is-light is-google is-rounded"
                      onClick={() => clickSignInSocial('Google')}>
                      <span className="icon">
                        <Icon path={mdiGoogle} size={1} />
                      </span>
                      <span>Sign in with Google</span>
                    </button>
                  </p>
                  <p className="control">
                    <button
                      type="button"
                      tabIndex={0}
                      className="button is-medium is-fullwidth is-light is-facebook is-rounded"
                      onClick={() => clickSignInSocial('Facebook')}>
                      <span className="icon">
                        <Icon path={mdiFacebook} size={1} />
                      </span>
                      <span>Sign in with Facebook</span>
                    </button>
                  </p>
                </div>
                <div className="signin-form-footer content is-medium">
                  <p className="is-font-brand-bold has-text-centered">
                    By signing in to Shift.ms you agree to our{' '}
                    <a
                      href="https://shift.ms/terms-of-use"
                      target="_blank"
                      rel="noreferrer">
                      terms of use
                    </a>{' '}
                    and{' '}
                    <a href="https://shift.ms/privacy-policy">privacy policy</a>
                    .
                  </p>
                  <p className="is-font-brand has-text-centered">
                    Don&apos;t have an account?{' '}
                    <Link href="/join">Join here</Link>.
                  </p>
                  <p className="is-font-brand has-text-centered">
                    Having trouble logging in?{' '}
                    <a
                      href="https://shiftms.typeform.com/to/h7ss1dhw"
                      target="_blank"
                      rel="nofollow noreferrer">
                      Please get in touch
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
